<template>
  <div class="row vh-100 justify-content-center">
    <div class="col col-lg-10 col-xl-8 align-self-center">
      <div class="text-center mb-5 mt-5">
        <a href="/">
          <img src="/images/coldrun_logo_inverse.svg" class="img-fluid logo" alt="Coldrun" />
        </a>
      </div>
      <h1 class="fs-5 mt-0">Front-end Developer (Vue)</h1>
      <h3 class="fs-6 fw-light d-flex align-items-center">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          fill="currentColor"
          class="bi bi-geo-alt me-2"
          viewBox="0 0 16 16"
        >
          <path
            d="M12.166 8.94c-.524 1.062-1.234 2.12-1.96 3.07A31.493 31.493 0 0 1 8 14.58a31.481 31.481 0 0 1-2.206-2.57c-.726-.95-1.436-2.008-1.96-3.07C3.304 7.867 3 6.862 3 6a5 5 0 0 1 10 0c0 .862-.305 1.867-.834 2.94zM8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10z"
          />
          <path d="M8 8a2 2 0 1 1 0-4 2 2 0 0 1 0 4zm0 1a3 3 0 1 0 0-6 3 3 0 0 0 0 6z" />
        </svg>
        Full-remote / Hybrid / Warsaw, Poland
      </h3>
      <h3 class="fs-6 d-flex align-items-center" style="color: #0dcaf0">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          fill="currentColor"
          class="bi bi-cash-stack me-2"
          viewBox="0 0 16 16"
        >
          <path d="M1 3a1 1 0 0 1 1-1h12a1 1 0 0 1 1 1H1zm7 8a2 2 0 1 0 0-4 2 2 0 0 0 0 4z" />
          <path
            d="M0 5a1 1 0 0 1 1-1h14a1 1 0 0 1 1 1v8a1 1 0 0 1-1 1H1a1 1 0 0 1-1-1V5zm3 0a2 2 0 0 1-2 2v4a2 2 0 0 1 2 2h10a2 2 0 0 1 2-2V7a2 2 0 0 1-2-2H3z"
          />
        </svg>
        Regular: 12,000 - 17,000 PLN + VAT
      </h3>
      <h3 class="fs-6 mb-4 d-flex align-items-center" style="color: #0dcaf0">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          fill="currentColor"
          class="bi bi-cash-stack me-2"
          viewBox="0 0 16 16"
        >
          <path d="M1 3a1 1 0 0 1 1-1h12a1 1 0 0 1 1 1H1zm7 8a2 2 0 1 0 0-4 2 2 0 0 0 0 4z" />
          <path
            d="M0 5a1 1 0 0 1 1-1h14a1 1 0 0 1 1 1v8a1 1 0 0 1-1 1H1a1 1 0 0 1-1-1V5zm3 0a2 2 0 0 1-2 2v4a2 2 0 0 1 2 2h10a2 2 0 0 1 2-2V7a2 2 0 0 1-2-2H3z"
          />
        </svg>
        Senior: 17,000 - 24,000 PLN + VAT
      </h3>
      <div class="fw-light mb-5">
        <p>
          Coldrun is a Warsaw-based software company run by developers with expertise in the latest
          web technologies. As a partner of an innovative tech company from the United States, we
          are looking to expand our team with a Front-end developer.
        </p>

        <p>
          As a Front-end developer, you will be responsible for developing UI components of
          cloud-based business web applications with a microservice architecture using the Vue.js
          framework.
        </p>
        <p class="mb-0">Requirements:</p>
        <ul>
          <li>Expert knowledge of <strong>HTML5, CSS3, Sass, JavaScript/ES6+</strong></li>
          <li>Experience with <strong>TypeScript</strong></li>
          <li>
            Very good knowledge of <strong>Vue.js 2+</strong>, including the ability to create
            reusable components as well as recognize repetitive parts and business logic of an
            application that should make up configurable components
          </li>
          <li>
            A good understanding of state management pattern and a centralized store concept
            implemented using <strong>Vuex</strong>
          </li>
          <li>
            Experience in working with <strong>Bootstrap 4+</strong> (preferably with 5+) and the
            ability to modify and extend its functionalities or create new components from scratch
          </li>
          <li>
            Willingness to gain knowledge and develop skills in the field of new frameworks,
            libraries, but also software design patterns and system architectures
          </li>
        </ul>
        <p class="mb-0">Nice to have:</p>
        <ul class="mb-5">
          <li><strong>Vuetify</strong> + Material design system knowledge</li>
          <li>UI/UX design experience</li>
          <li>
            Hands-on experience with <strong>React</strong>, <strong>AngularJS 1.x</strong> or
            <strong>Angular 2+</strong>
          </li>
          <li>
            Knowledge about TDD principles and hands-on experience in creating tests using
            <strong>Jest</strong>
          </li>
          <li>
            Any experience with the following back-end stacks:
            <ul>
              <li>
                <strong>Node.js</strong>, <strong>Express.js</strong> + <strong>MongoDB</strong>
              </li>
              <li><strong>.NET Core</strong> + <strong>MSSQL</strong></li>
            </ul>
          </li>
          <li>
            Any experience with setting up and managing CI/CD pipelines on
            <strong>Azure</strong> or <strong>AWS</strong> cloud platforms
          </li>
          <li>
            Practical knowledge of utilizing <strong>Jira</strong> and Confluence in the software
            development life-cycle
          </li>
        </ul>
        <div class="d-grid col-sm-6 col-md-5 col-lg-4 col-xl-5 col-xxl-4 mx-auto">
          <a
            class="btn btn-primary btn-lg btn-block mb-4"
            href="https://coldrun.traffit.com/public/form/a/d1AwPQ=="
            target="_blank"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              class="bi bi-box-arrow-up-right"
              viewBox="0 0 16 16"
            >
              <path
                fill-rule="evenodd"
                d="M8.636 3.5a.5.5 0 0 0-.5-.5H1.5A1.5 1.5 0 0 0 0 4.5v10A1.5 1.5 0 0 0 1.5 16h10a1.5 1.5 0 0 0 1.5-1.5V7.864a.5.5 0 0 0-1 0V14.5a.5.5 0 0 1-.5.5h-10a.5.5 0 0 1-.5-.5v-10a.5.5 0 0 1 .5-.5h6.636a.5.5 0 0 0 .5-.5z"
              />
              <path
                fill-rule="evenodd"
                d="M16 .5a.5.5 0 0 0-.5-.5h-5a.5.5 0 0 0 0 1h3.793L6.146 9.146a.5.5 0 1 0 .708.708L15 1.707V5.5a.5.5 0 0 0 1 0v-5z"
              />
            </svg>
            Apply
          </a>
        </div>
      </div>
      <div class="text-center mb-5">
        <address class="mb-4 fw-light">
          <strong>Coldrun</strong><br />
          Rzymowskiego 53<br />
          02-697 Warsaw<br />
          Poland
        </address>
        <a
          href="https://www.facebook.com/Coldrun-818187288538962"
          target="_blank"
          class="text-decoration-none"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            fill="currentColor"
            class="bi bi-facebook"
            viewBox="0 0 16 16"
          >
            <path
              d="M16 8.049c0-4.446-3.582-8.05-8-8.05C3.58 0-.002 3.603-.002 8.05c0 4.017 2.926 7.347 6.75 7.951v-5.625h-2.03V8.05H6.75V6.275c0-2.017 1.195-3.131 3.022-3.131.876 0 1.791.157 1.791.157v1.98h-1.009c-.993 0-1.303.621-1.303 1.258v1.51h2.218l-.354 2.326H9.25V16c3.824-.604 6.75-3.934 6.75-7.951z"
            />
          </svg>
        </a>
        <a
          href="https://twitter.com/coldrunIT"
          target="_blank"
          class="ms-4 me-4 text-decoration-none"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            fill="currentColor"
            class="bi bi-twitter"
            viewBox="0 0 16 16"
          >
            <path
              d="M5.026 15c6.038 0 9.341-5.003 9.341-9.334 0-.14 0-.282-.006-.422A6.685 6.685 0 0 0 16 3.542a6.658 6.658 0 0 1-1.889.518 3.301 3.301 0 0 0 1.447-1.817 6.533 6.533 0 0 1-2.087.793A3.286 3.286 0 0 0 7.875 6.03a9.325 9.325 0 0 1-6.767-3.429 3.289 3.289 0 0 0 1.018 4.382A3.323 3.323 0 0 1 .64 6.575v.045a3.288 3.288 0 0 0 2.632 3.218 3.203 3.203 0 0 1-.865.115 3.23 3.23 0 0 1-.614-.057 3.283 3.283 0 0 0 3.067 2.277A6.588 6.588 0 0 1 .78 13.58a6.32 6.32 0 0 1-.78-.045A9.344 9.344 0 0 0 5.026 15z"
            />
          </svg>
        </a>
        <a
          href="https://www.linkedin.com/company/coldrun"
          target="_blank"
          class="text-decoration-none"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            fill="currentColor"
            class="bi bi-linkedin"
            viewBox="0 0 16 16"
          >
            <path
              d="M0 1.146C0 .513.526 0 1.175 0h13.65C15.474 0 16 .513 16 1.146v13.708c0 .633-.526 1.146-1.175 1.146H1.175C.526 16 0 15.487 0 14.854V1.146zm4.943 12.248V6.169H2.542v7.225h2.401zm-1.2-8.212c.837 0 1.358-.554 1.358-1.248-.015-.709-.52-1.248-1.342-1.248-.822 0-1.359.54-1.359 1.248 0 .694.521 1.248 1.327 1.248h.016zm4.908 8.212V9.359c0-.216.016-.432.08-.586.173-.431.568-.878 1.232-.878.869 0 1.216.662 1.216 1.634v3.865h2.401V9.25c0-2.22-1.184-3.252-2.764-3.252-1.274 0-1.845.7-2.165 1.193v.025h-.016a5.54 5.54 0 0 1 .016-.025V6.169h-2.4c.03.678 0 7.225 0 7.225h2.4z"
            />
          </svg>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import { useMeta } from 'vue-meta';

export default {
  name: 'front-end-developer-vue',
  setup() {
    useMeta({
      title: 'Front-end Developer (Vue) job',
      og: {
        title: 'Front-end Developer (Vue) @Coldrun',
        type: 'website',
        url: 'https://coldrun.com/jobs/front-end-developer-vue',
        image: 'https://coldrun.com/images/jobs/og-front-end-vue-solid-x2.png',
        'image:width': '2400',
        'image:height': '1260',
        'image:type': 'image/png',
        'image:alt': 'Front-end Developer (Vue) @Coldrun - Apply',
        description: '12,000 - 24,000 PLN + VAT (B2B) | Full-remote / Hybrid / Warsaw, Poland',
      },
    });
  },
};
</script>

<style scoped></style>
